import React from 'react';

const First = ({ lang }) => {
  return (
    <section id="first" className="section first">
      {lang === 'en' ? (
        <h3 className="first-title">
          THE FIRST COMMUNITY
          <br />
          <b>UIT NFT COLLECTION</b>
        </h3>
      ) : (
        <h3 className="first-title">
          Перша NFT Колекція
          <br />
          <b>Ком’юніті UIT</b>
        </h3>
      )}

      <div className="first-content">
        <div className="first-content-left">
          <img
            loading="lazy"
            width={526}
            height={488}
            src="./assets/first-img.webp"
            alt="first collection"
          />
        </div>
        <div className="first-content-right">
          {lang === 'en' ? (
            <p className="first-content-right-title">
              Limited series of unique
              <br />
              crypto-artifacts of the community
            </p>
          ) : (
            <p className="first-content-right-title">
              Обмежена серія унікальних
              <br />
              крипто-артефактів спільноти
            </p>
          )}

          <ul>
            <li>
              <img
                loading="lazy"
                width={24}
                height={17}
                src="./assets/first-check.svg"
                alt="check"
              />
              <p>
                {lang === 'en'
                  ? 'Endows each NFT holder with exclusive privileges'
                  : 'Наділяє ексклюзивними перевагами кожного володаря NFT'}
              </p>
            </li>
            <li>
              <img
                loading="lazy"
                width={24}
                height={17}
                src="./assets/first-check.svg"
                alt="check"
              />
              <p>
                {lang === 'en'
                  ? 'Limited edition – only 250 pieces'
                  : 'Лімітований тираж - лише 250 штук'}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="first-blur1"></div>
      <div className="first-blur2"></div>
      <div className="first-blur3"></div>
    </section>
  );
};

export default First;
