import React from 'react';
import SectionsBtn from '../ui/SectionsBtn';

const Hero = ({ lang }) => {
  return (
    <section className="section hero">
      <div className="hero-content">
        <div className="hero-left">
          <h1 className="hero-title">
            UIT NFT{' '}
            <span>
              <img
                width={95}
                height={55}
                src="./assets/hero-title-icon.svg"
                alt="hero title icon"
              />
            </span>
            <br />
            COLLECTION
            <br />
            <span>LIMITED EDITION</span>
          </h1>

          <SectionsBtn lang={lang} mod="hero-btn" />
        </div>
        <div className="hero-right">
          <img
            className="hero-right-img"
            width={593}
            height={663}
            src="./assets/hero-img.webp"
            alt="hero image"
          />
          <div className="hero-right-badge">
            <img
              className="hero-right-badge-bg"
              src="./assets/badge.png"
              alt="icon"
            />
            <img
              className="hero-right-badge-star"
              width={22}
              height={22}
              src="./assets/hero-star.svg"
              alt="icon"
            />
            {lang === 'en' ? (
              <>
                <p className="hero-right-badge-title">Limited edition</p>
                <p className="hero-right-badge-subtitle">
                  <span>250</span> <b>pieces</b>
                </p>
              </>
            ) : (
              <>
                <p className="hero-right-badge-title">Лімітована серія</p>
                <p className="hero-right-badge-subtitle">
                  <span>250</span> <b>штук</b>
                </p>
              </>
            )}
          </div>
        </div>
        <SectionsBtn lang={lang} mod="hero-btn hero-btn-mob" />

        <div className="hero-blur1"></div>
        <div className="hero-blur2"></div>
        <div className="hero-blur3"></div>
        <div className="hero-blur4"></div>
        <div className="hero-blur5"></div>
        <div className="hero-blur6"></div>
        <div className="hero-blur7"></div>
      </div>
      <img
        className="hero-decor-img"
        width={577}
        height={271}
        src="./assets/hero-decor-img.webp"
        alt="hero image"
      />
      <img
        loading="lazy"
        className="first-decor"
        width={680}
        height={505}
        src="./assets/first-decor.webp"
        alt="decor"
      />
    </section>
  );
};

export default Hero;
