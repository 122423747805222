import React from 'react';
import { useDispatch } from 'react-redux';
import { setBurger } from '../../store/slice';
import { Link } from 'react-scroll';

const HeaderNav = ({ lang, locale, policy }) => {
  const dispatch = useDispatch();
  const handleNav = () => {
    if (locale === 'inBurger') {
      dispatch(setBurger(false));
      document.querySelector('body').style.overflow = 'auto';
    }
  };

  return (
    <nav>
      <ul className={`header__nav ${locale} ${policy === true ? 'hide' : ''}`}>
        <li className="header__nav-item">
          <Link
            onClick={handleNav}
            to="target"
            spy={true}
            smooth={false}
            duration={500}
            offset={-50}
          >
            {lang === 'en' ? 'The purpose of the collection' : 'Ціль колекції'}
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            onClick={handleNav}
            to="advantages"
            spy={true}
            smooth={false}
            duration={500}
            offset={-50}
          >
            {lang === 'en' ? 'Benefits' : 'Переваги'}
          </Link>
        </li>
        <li className="header__nav-item">
          <Link
            onClick={handleNav}
            to="buy"
            spy={true}
            smooth={false}
            duration={500}
            offset={-50}
          >
            {lang === 'en' ? 'Become a participantnefits' : 'Стати учасником'}
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default HeaderNav;
