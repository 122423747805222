import React, { Suspense, lazy, useEffect, useState } from 'react';
import Header from './components/Header/Header';
import Hero from './components/SectionHero/Hero';
import { Helmet } from 'react-helmet';
import First from './components/SectionFirst/First';
import { useLocation, useNavigate } from 'react-router-dom';
const Members = lazy(() => import('./components/SectionAdvantages/Advantages'));
const Target = lazy(() => import('./components/SectionTarget/Target'));
const Buy = lazy(() => import('./components/SectionBuy/Buy'));
const Support = lazy(() => import('./components/SectionSupport/Support'));
const Footer = lazy(() => import('./components/Footer/Footer'));

const Homepage = ({ lang, userLanguage }) => {
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get('ref');
    if (myParam) {
      localStorage.setItem('ref', myParam);
    }
    window.scrollTo(0, 0);
  }, []);

  document
    .querySelector('html')
    .setAttribute('lang', lang === 'ua' ? 'uk' : 'en');

  if (lang === 'ua') {
    document.querySelector('meta[name="description"]').content =
      'Приєднуйтеся до UNITED INVEST TEAM і отримайте знання, які розкриють перед вами світ інвестицій та фінансової незалежності.';
  }
  if (lang === 'en') {
    document.querySelector('meta[name="description"]').content =
      'Join UNITED INVEST TEAM and gain the knowledge that will open up the world of investments and financial independence to you.';
  }

  const navigate = useNavigate();

  const [lazyValue, setLazyValue] = useState(false);

  useEffect(() => {
    if (
      userLanguage === 'uk' ||
      userLanguage === 'ru' ||
      userLanguage === 'ru-RU' ||
      userLanguage === 'uk-UA'
    ) {
      navigate('/');
    } else {
      navigate('/en');
    }
    setTimeout(() => {
      setLazyValue(true);
    }, 1000);
  }, []);

  return (
    <>
      <div className="container">
        <Helmet>
          <title>
            {lang === 'ua' ? 'UIT NFT КОЛЕКЦІЯ' : 'UIT NFT COLLECTION'}
          </title>
        </Helmet>
        <Header lang={lang} />
        <div className="hero-container">
          <Hero lang={lang} />
        </div>
      </div>
      <div className="first-container">
        <First lang={lang} />
      </div>
      {lazyValue && (
        <Suspense fallback={<div>Loading...</div>}>
          <div className="container">
            <Target lang={lang} />
            <Members lang={lang} />
            <Buy lang={lang} />
          </div>
          <Support lang={lang} />
          <div className="container">
            <Footer lang={lang} />
          </div>
        </Suspense>
      )}
    </>
  );
};

export default Homepage;
