import React from 'react';

const SectionsBtn = ({ mod, lang }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://opensea.io/assets/matic/0xd6105d41c0d06d628c677e91e02052260cd0bcb6/2"
      className={`section-btn ${mod ? mod : ''}`}
    >
      {lang === 'en' ? 'Buy' : 'Купити'}
      <img loading="lazy" src="./assets/btn-arrow.svg" alt="btn" />
    </a>
  );
};

export default SectionsBtn;
