import React, { Suspense, lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/Header/Header';
const Footer = lazy(() => import('./components/Footer/Footer'));
const OfferEn = lazy(() => import('./components/Policy/OfferEn'));
const OfferUa = lazy(() => import('./components/Policy/OfferUa'));

const Legal = ({ lang }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <Helmet>
        <title>{lang === 'ua' ? 'ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ' : 'UIT EN'}</title>
        <meta
          name="description"
          content={lang === 'ua' ? 'INVEST TEAM UA' : 'INVEST TEAM EN'}
        />
      </Helmet>
      <Header lang={lang} policy={true} />
      <Suspense fallback={<div>Loading...</div>}>
        {lang === 'en' ? <OfferEn /> : <OfferUa />}
        <Footer lang={lang} policy={true} />
      </Suspense>
    </div>
  );
};

export default Legal;
