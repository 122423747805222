import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const HeaderRight = ({ lang, locale }) => {
  const [langSelect, setLangSelect] = useState('hide');
  const handleDropdown = () => {
    setLangSelect((prev) => (prev === 'hide' ? 'active' : 'hide'));
  };

  return (
    <div className={`header__right ${locale}`}>
      <div className="header__right-lang" onClick={handleDropdown}>
        {lang === 'en' ? 'En' : 'Ua'}
        <img
          className={langSelect === 'active' ? 'up-down' : ''}
          width={8}
          height={8}
          src="./assets/lang-arrow.svg"
          alt="lang"
        />
        <Link
          to={lang === 'en' ? '/' : '/en'}
          className={`header__lang-options ${langSelect}`}
        >
          {lang === 'en' ? 'Ua' : 'En'}
        </Link>
      </div>
      <a
        className="header__right-btn"
        target="_blank"
        rel="noopener noreferrer"
        href="https://opensea.io/assets/matic/0xd6105d41c0d06d628c677e91e02052260cd0bcb6/2"
      >
        {lang === 'en' ? 'Buy' : 'Купити'}
      </a>
    </div>
  );
};

export default HeaderRight;
