import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  burger: false,
};

const slice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    setBurger: (state, action) => {
      state.burger = action.payload;
    },
  },
});

export const { setBurger } = slice.actions;
export default slice.reducer;
